import React from 'react';
import { FaHome, FaHeart, FaList, FaShoppingCart, FaUser } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useModal } from '../../contexts/ModalContext';
import './MobileFooter.css'; // Add CSS for mobile footer

const MobileFooter = () => {
  const location = useLocation();
  const { openModal } = useModal();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
  };

  return (
    <footer className="mobile-footer">
      <Link to="/">
        <FaHome className={`icon ${location.pathname === '/' ? 'active' : ''}`} />
      </Link>
      {/* <Link to="/favorites">
        <FaHeart className={`icon ${location.pathname === '/favorites' ? 'active' : ''}`} />
      </Link> */}
      <a href="/categories" onClick={handleCategoryClick}>
        <FaList className={`icon ${location.pathname === '/categories' ? 'active' : ''}`} />
      </a>
      <a href="/cart" onClick={handleCategoryClick}>
        <FaShoppingCart className={`icon ${location.pathname === '/cart' ? 'active' : ''}`} />
      </a>
      <a href="/profile" onClick={handleCategoryClick}>
        <FaUser className={`icon ${location.pathname === '/profile' ? 'active' : ''}`} />
      </a>
    </footer>
  );
};

export default MobileFooter;
