import React from 'react';
import './ReturnPolicy.css';

const ReturnPolicy = () => {
  return (
    <div className="return-policy-page">
      <div className="return-policy-content">
        <h1>退换货条款</h1>
        <p className="policy-intro">国珍酒业致力于为您提供优质的产品和服务。我们理解有时您可能需要退换商品，因此制定了以下退换货政策。请在购买前仔细阅读。</p>
        
        <section>
          <h2>1. 退换货期限</h2>
          <p>自收到商品之日起7天内，如果产品未开封且保持原状，您可以申请退换货。特殊促销商品可能有不同的退换货政策，请查看具体商品说明。</p>
        </section>

        <section>
          <h2>2. 退换货条件</h2>
          <p>我们接受以下情况的退换货：</p>
          <ul>
            <li>收到的商品与订单不符</li>
            <li>商品在运输过程中损坏</li>
            <li>商品存在质量问题</li>
            <li>未开封的商品（自收到后7天内）</li>
          </ul>
          <p>以下情况不予退换：</p>
          <ul>
            <li>已开封或使用过的商品（质量问题除外）</li>
            <li>因个人喜好问题（如口感、香型不合适）要求退换的商品</li>
            <li>超过退换货期限的商品</li>
          </ul>
        </section>

        <section>
          <h2>3. 退换货流程</h2>
          <ol>
            <li>联系我们的客户服务，说明退换货原因并提供订单号</li>
            <li>我们的客服人员会审核您的申请，并在24小时内给予回复</li>
            <li>如果申请被批准，我们会提供退货地址和退货单</li>
            <li>请将商品原包装、配件、发票等随商品一起寄回</li>
            <li>我们收到退回的商品后，会进行检查</li>
            <li>检查无误后，我们会为您办理退款或发送新的商品</li>
          </ol>
        </section>

        <section>
          <h2>4. 退款方式</h2>
          <p>退款将原路返回您的支付账户。根据不同的支付方式和银行，退款可能需要3-15个工作日完成。</p>
        </section>

        <section>
          <h2>5. 运费说明</h2>
          <p>如果退换货是因为我们的失误（如发错货、商品质量问题），我们将承担来回运费。如果是因为您个人原因申请退换货，退货运费需要您自行承担，换货则需要您承担寄回的运费。</p>
        </section>

        <section>
          <h2>6. 商品包装要求</h2>
          <p>请确保退回的商品使用适当的包装，以免在运输过程中损坏。商品应保持原有的标签、配件和包装。对于因包装不当导致的商品损坏，我们可能会收取额外的费用或拒绝退换。</p>
        </section>

        <section>
          <h2>7. 特殊情况</h2>
          <p>对于定制商品、限量版商品或促销商品，可能适用特殊的退换货政策。请在购买前仔细查看商品详情页面的相关说明。</p>
        </section>

        <section>
          <h2>8. 联系我们</h2>
          <p>如果您对我们的退换货政策有任何疑问，或需要申请退换货，请联系我们的客户服务：</p>
          <p>客服电话：400-881-3519</p>
          <p>客服邮箱：service@jiuhaozhan.com</p>
          <p>客服在线时间：周一至周五 9:00-18:00（节假日除外）</p>
        </section>

        <p className="policy-footer">最后更新日期：2024年10月15日</p>
      </div>
    </div>
  );
};

export default ReturnPolicy;