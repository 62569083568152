import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-page">
      <div className="terms-content">
        <h1>服务条款</h1>
        <p className="terms-intro">欢迎访问国珍酒业官方网站。请仔细阅读以下条款，使用我们的网站、产品和服务即表示您同意这些条款。</p>
        
        <section>
          <h2>1. 接受条款</h2>
          <p>通过访问或使用国珍酒业的网站、移动应用程序或任何相关服务（统称为"服务"），您同意受这些服务条款的约束。如果您不同意这些条款的任何部分，请不要使用我们的服务。</p>
        </section>

        <section>
          <h2>2. 服务说明</h2>
          <p>国珍酒业提供白酒相关的在线信息、产品展示和电子商务服务。我们保留随时修改、暂停或终止部分或全部服务的权利，恕不另行通知。</p>
        </section>

        <section>
          <h2>3. 用户责任</h2>
          <p>您同意：</p>
          <ul>
            <li>提供准确、最新和完整的个人信息。</li>
            <li>保护您的账户信息安全，对使用您的账户进行的所有活动负责。</li>
            <li>不以任何非法或未经授权的方式使用我们的服务。</li>
            <li>不干扰或破坏我们服务的安全特性。</li>
          </ul>
        </section>

        <section>
          <h2>4. 知识产权</h2>
          <p>国珍酒业网站上的所有内容，包括但不限于文字、图像、标志、音频和视频剪辑，均为国珍酒业或其内容提供商的财产，受版权法保护。未经明确书面许可，不得复制、分发、修改或以其他方式使用这些内容。</p>
        </section>

        <section>
          <h2>5. 免责声明</h2>
          <p>国珍酒业的服务按"原样"提供，不提供任何明示或暗示的保证。我们不保证服务将不间断、及时、安全或无错误，也不保证结果将准确或可靠。</p>
        </section>

        <section>
          <h2>6. 责任限制</h2>
          <p>在法律允许的最大范围内，国珍酒业及其员工、管理人员、董事或代理人不对任何直接、间接、附带、特殊、后果性或惩罚性损害负责，包括但不限于利润损失、商誉、使用、数据或其他无形损失。</p>
        </section>

        <section>
          <h2>7. 适用法律</h2>
          <p>这些条款受中华人民共和国法律管辖并按其解释。任何与这些条款有关的争议应提交至贵州省仁怀市有管辖权的法院解决。</p>
        </section>

        <section>
          <h2>8. 修改</h2>
          <p>我们保留随时修改这些服务条款的权利。修改后的条款将在本页面上发布。继续使用我们的服务即表示您接受修改后的条款。</p>
        </section>

        <section>
          <h2>9. 联系我们</h2>
          <p>如果您对这些服务条款有任何问题或疑虑，请联系我们：</p>
          <p>电子邮件：legal@jiuhaozhan.com</p>
          <p>电话：400-881-3519</p>
          <p>地址：贵州省遵义市仁怀市茅台镇岩滩村</p>
        </section>

        <p className="terms-footer">最后更新日期：2024年10月15日</p>
      </div>
    </div>
  );
};

export default Terms;