// src/pages/ContactUs.js
import React, { useState } from 'react';
import './ContactUs.css'; // Ensure this file contains your styles

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className="contact-us-page">
      <div className="contact-content">
        {/* Contact Info Section */}
        <div className="contact-info">
          <h1>联系我们</h1>
          <p>如果您有任何问题,请随时联系我们!</p>
          
          <div className="info-item">
            <h3>电子邮件:</h3>
            <p>service@jiuhaozhan.top</p>
          </div>
          <div className="info-item">
            <h3>联系电话:</h3>
            <p>4008813519</p>
          </div>
          <div className="info-item">
            <h3>地址:</h3>
            <p>贵州省遵义市仁怀市茅台镇岩滩村</p>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="contact-form">
          <h2>留言我们</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">姓名:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">电子邮件:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">留言:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">发送留言</button>
          </form>
        </div>
      </div>

      {/* Google Maps Section */}
      <div className="contact-map">
        <h2>我們的位置</h2>
        <iframe
          title="map"
          src="https://j.map.baidu.com/3b/invi"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
