import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from './components/Header/Header';
import MobileFooter from './components/Header/MobileFooter';
import FavoritesPage from './pages/FavoritesPage';
import CategoriesPage from './pages/CategoriesPage';
import CartPage from './pages/CartPage';
import ProfilePage from './pages/ProfilePage';
import HomePage from './components/HomePage/HomePage';
import ProductDetail from './components/ProductDetail/ProductDetail';
import Footer from './components/Footer/Footer';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import FAQ from './components/faq/FAQ';
import Terms from './components/terms/Terms';
import ReturnPolicy from './components/returnpolicy/ReturnPolicy';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import { ModalProvider } from './contexts/ModalContext';
import Modal from './components/Modal';

function App() {
  return (
    <Router>
      <ModalProvider>
        <div className="App">
          <Header />
          
          {/* Define all the routes here */}
          <Routes>
            {/* HomePage will be shown at the root path */}
            <Route path="/" element={<HomePage />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/favorites" element={<FavoritesPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          </Routes>

          <Footer />
          <Modal />
          <MobileFooter /> {/* Mobile footer stays here */}
        </div>
      </ModalProvider>
    </Router>
  );
}

export default App;
