
const products = [
    {
      title: "纪念 1949",
      subtitle: "大国吉祥（纪念1949）",
      image: "/img/products/1.jpg",
      price: "798 元",
      detailsLink: "/product/ispita",
      description: "大国吉祥（纪念1949） 规格500ml 53° 酱香型白酒",
      longDescription: `
                        大国吉祥系列白酒是为致敬新中国成立而精心打造的高端酱香型白酒，彰显了历史与传统的深厚底蕴。该系列酒在选料上极为严苛，精选上等原料，并遵循古法酿造工艺，历经多道复杂的传统制作流程，确保每一滴酒都拥有丰富的酱香层次。入口时，酒体醇厚圆润，带有细腻的回甘，且余味悠长，令人回味无穷。
                        \n\n大国吉祥不仅仅是品质的象征，更代表了中国悠久的酿酒文化与非凡的工艺传承。无论是作为纪念新中国成立的珍藏品，还是作为馈赠亲朋的高端礼品，该系列白酒都完美体现了尊贵与经典的融合。大国吉祥系列还拥有多种规格，适应不同的需求，既有收藏价值，又具备极高的品鉴体验，是品味历史与文化的绝佳选择。
                        `,
      images: ["/img/products/1.jpg", "/img/products/2.jpg", "/img/products/3.jpg"],
      variations: ["500ml"],
      type: "酱香型",
      alcoholContent: "53°",
      origin: "贵州 仁怀",
    },
    {
      title: "纪念 1978",
      subtitle: "大国吉祥（纪念1978）",
      image: "/img/products/1.jpg",
      price: "598 元 ",
      detailsLink: "/product/bad-boy-bordeaux",
      description: "大国吉祥（纪念1978） 规格500ml 53° 酱香型白酒",
      longDescription: `
                        大国吉祥系列白酒是为致敬新中国成立而精心打造的高端酱香型白酒，彰显了历史与传统的深厚底蕴。该系列酒在选料上极为严苛，精选上等原料，并遵循古法酿造工艺，历经多道复杂的传统制作流程，确保每一滴酒都拥有丰富的酱香层次。入口时，酒体醇厚圆润，带有细腻的回甘，且余味悠长，令人回味无穷。
                        \n\n大国吉祥不仅仅是品质的象征，更代表了中国悠久的酿酒文化与非凡的工艺传承。无论是作为纪念新中国成立的珍藏品，还是作为馈赠亲朋的高端礼品，该系列白酒都完美体现了尊贵与经典的融合。大国吉祥系列还拥有多种规格，适应不同的需求，既有收藏价值，又具备极高的品鉴体验，是品味历史与文化的绝佳选择。
                        `,      images: ["/img/products/1.jpg", "/img/products/2.jpg", "/img/products/3.jpg"],
      variations: ["500ml"],
      type: "酱香型",
      alcoholContent: "53°",
      origin: "贵州 仁怀",
    },
    // More product data...
  ];
  
  export default products;
  