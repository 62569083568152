import React from 'react';
import Slider from 'react-slick';
import './WineSlider.css'; // Add CSS for styling
import { useModal } from '../../../contexts/ModalContext';

const WineSlider = () => {
  const { openModal } = useModal();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
  };
  const slides = [
    {
      title: "大国吉祥（纪念1949）",
      content: "大国吉祥（纪念1949）是为纪念新中国成立打造的高端酱香型白酒，精选优质原料，口感醇厚，余味悠长。每瓶500毫升，定价798元，适合作为收藏或礼品。",
      price: "¥ 798",
      info: "每件6瓶，1件起售",
    },
    {
      title: "大国吉祥（纪念1949）",
      content: "大国吉祥（纪念1949）是为纪念新中国成立打造的高端酱香型白酒，精选优质原料，口感醇厚，余味悠长。每瓶500毫升，定价798元，适合作为收藏或礼品。",
      price: "¥ 798",
      info: "每件6瓶，1件起售",
    },
    {
      title: "大国吉祥（纪念1949）",
      content: "大国吉祥（纪念1949）是为纪念新中国成立打造的高端酱香型白酒，精选优质原料，口感醇厚，余味悠长。每瓶500毫升，定价798元，适合作为收藏或礼品。",
      price: "¥ 798",
      info: "每件6瓶，1件起售",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="wine-slider">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            {/* Left content */}
            <div className="left-content">
              <h2>{slide.title}</h2>
              <p>{slide.content}</p>
            </div>

            {/* Center image */}
            <div className="center-image">
              <img src="/img/slider/slide.png" alt="Wine Bottle" />
            </div>

            {/* Right content */}
            <div className="right-content">
              <h3>{slide.price}</h3>
              <select>
                <option value="500ml">500ml</option>
                {/* <option value="1L">1L</option> */}
                {/* <option value="1.5L">1.5L</option> */}
              </select>
              <button className="add-to-cart" onClick={handleCategoryClick}>加入购物车</button>
              <p>{slide.info}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WineSlider;
