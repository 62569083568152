import React, { useState } from 'react';
import { FaSearch, FaShoppingCart, FaUser, FaBars, FaWeixin, FaTiktok, FaWeibo } from 'react-icons/fa';
import { useModal } from '../../contexts/ModalContext';
import './Header.css';

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { openModal } = useModal();

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
    toggleDrawer();
  };

  return (
    <header className="header">
      {/* Hamburger Icon on mobile */}
      <div className="hamburger-icon" onClick={toggleDrawer}>
        <FaBars />
      </div>
      <div className="logo">
        <a href="/"><img src="/img/logo.png" alt="酒号站" className="header-logo" /></a>
      </div>
      {/* Navigation Menu (PC) */}
      <nav className="menu">
        <a href="/">首页</a>
        <a href="/about">关于我们</a>
        <a href="/contact">联系我们</a>
      </nav>
      {/* Icons */}
      <div className="icons">
        <FaSearch className="icon" />
        <FaShoppingCart className="icon" />
        <FaUser className="icon" />
      </div>
      {/* Drawer Menu for Mobile */}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <div className="drawer-section main-nav">
          <a href="/" onClick={toggleDrawer}>首页</a>
          <a href="/about" onClick={toggleDrawer}>关于我们</a>
          <a href="/contact" onClick={toggleDrawer}>联系我们</a>
        </div>
        <div className="drawer-section">
          <h4>店铺</h4>
          <a href="/shop/new-arrival" onClick={handleCategoryClick}>新品上市</a>
          <a href="/shop/best-sellers" onClick={handleCategoryClick}>畅销品</a>
        </div>
        <div className="drawer-section">
          <h4>类别</h4>
          <a href="/categories/red-wine" onClick={handleCategoryClick}>大国吉祥</a>
          <a href="/categories/white-wine" onClick={handleCategoryClick}>更多</a>
        </div>
        <div className="drawer-section">
          <h4>了解更多</h4>
          <a href="/faqs" onClick={toggleDrawer}>常见问题解答</a>
          <a href="/terms" onClick={toggleDrawer}>服务条款</a>
          <a href="/return-policy" onClick={toggleDrawer}>退货政策</a>
          <a href="/privacy-policy" onClick={toggleDrawer}>隐私协议</a>
        </div>
        <div className="drawer-section">
          <h4>跟随我们</h4>
          <div className="social-icons">
            <a href="https://weixin.com" target="_blank" rel="noopener noreferrer"><FaWeixin /></a>
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
            <a href="https://weibo.com" target="_blank" rel="noopener noreferrer"><FaWeibo /></a>
          </div>
        </div>
      </div>
      {/* Overlay for Drawer */}
      {isDrawerOpen && <div className="overlay" onClick={toggleDrawer}></div>}
    </header>
  );
};

export default Header;