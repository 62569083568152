import React from 'react';
import { FaWeixin, FaTiktok, FaWeibo } from 'react-icons/fa';
import './Footer.css'; // Add CSS for footer styling
import { useModal } from '../../contexts/ModalContext';

const Footer = () => {
  const { openModal } = useModal();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and About */}
        <div className="footer-column">
          <img src="/img/logo.png" alt="Wine Website Logo" className="footer-logo" />
          
          <p className="about-text">
          探索来自大国吉祥的酱香型白酒，品鉴新品与畅销佳酿。
          </p>
        </div>

        {/* Shop Menu */}
        <div className="footer-column">
          <h4>店鋪</h4>
          <ul>
            <li><a href="/shop/new-arrival" onClick={handleCategoryClick}>新品上市</a></li>
            <li><a href="/shop/best-sellers" onClick={handleCategoryClick}>畅销品</a></li>
            {/* <li><a href="/shop">選購所有葡萄酒</a></li> */}
            {/* <li><a href="/categories">类别</a></li> */}
          </ul>
        </div>

        {/* Category Menu */}
        <div className="footer-column">
          <h4>类别</h4>
          <ul>
            <li><a href="/categories/red-wine" onClick={handleCategoryClick}>大国吉祥</a></li>
            <li><a href="/categories/white-wine" onClick={handleCategoryClick}>更多</a></li>
            {/* <li><a href="/categories/rose-wine">桃紅葡萄酒</a></li>
            <li><a href="/categories/sparkling-wine">氣泡酒</a></li>
            <li><a href="/categories/dessert-wine">甜酒</a></li> */}
          </ul>
        </div>

        {/* Learn More Menu */}
        <div className="footer-column">
          <h4>了解更多</h4>
          <ul>
            <li><a href="/faqs">常见问题解答</a></li>
            <li><a href="/contact">联系我们</a></li>
            <li><a href="/terms">服务条款</a></li>
            <li><a href="/return-policy">退货政策</a></li>
            <li><a href="/privacy-policy">隐私协议</a></li>
          </ul>
        </div>

        {/* Follow Us Section */}
        <div className="footer-column">
          <h4>跟随我们</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaWeixin /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaWeibo /></a>
            {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        {/* Please don't remove the credit it help us to get clients. We are a new startup please help us to grow. */}
        <p>Your Company &copy; {new Date().getFullYear()} Design by 大国吉祥 </p>

        {/* <p>Your Company &copy; {new Date().getFullYear()} Design by <a href="http://thewebgroup.in/" className='ft-link-copy'>THE WEB</a></p> */}
      </div>
    </footer>
  );
};

export default Footer;
