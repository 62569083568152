import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-page">
      <div className="about-content">
        <section className="company-intro">
          <h1>贵州省仁怀市茅台镇国珍酒业(集团)有限公司</h1>
          <div className="intro-content">
            <div className="text-content">
              <h2>企业简介</h2>
              <p>贵州省仁怀市茅台镇国珍酒业(集团)有限公司位于中国白酒圣地——贵州省遵义市仁怀市茅台镇岩滩村。这片土地以其得天独厚的自然环境和悠久的酿酒历史闻名于世，为酱香型白酒的酿造提供了最佳的自然条件。</p>
              <p>国珍酒业集团秉承茅台镇传统酿造工艺，结合现代化技术，不断创新发展，以传承与创新为核心理念，打造出众多高端酱香型白酒产品。公司致力于将茅台镇的酒文化发扬光大，将中国酱香型白酒的精髓传播至全球。</p>
            </div>
            <div className="image-placeholder">
              <img src="/img/about/1.png" alt="国珍酒业厂区" />
            </div>
          </div>
        </section>

        <section className="mission-vision">
          <div className="mission">
            <h2>企业使命</h2>
            <p>国珍酒业集团以弘扬中国传统酿酒文化为己任，专注于高端白酒的生产与研发。公司坚持采用高品质的原料，依托茅台镇得天独厚的酿造环境和水土优势，严格把控每一道工序，确保每一瓶酒都具备浓郁的酱香特色和卓越的品质。</p>
          </div>
          <div className="vision">
            <h2>企业愿景</h2>
            <p>国珍酒业集团致力于成为中国酱香型白酒领域的领先企业，秉承"品质至上、传承文化"的原则，力求为消费者提供最佳的酱香型白酒体验。同时，公司致力于推动茅台镇酒文化的国际化发展，将中国的酱香白酒推向世界舞台。</p>
          </div>
        </section>

        <section className="company-info">
          <h2>企业信息</h2>
          <div className="info-grid">
            <div className="info-item">
              <h3>企业名称</h3>
              <p>贵州省仁怀市茅台镇国珍酒业(集团)有限公司</p>
            </div>
            <div className="info-item">
              <h3>企业地址</h3>
              <p>贵州省遵义市仁怀市茅台镇岩滩村</p>
            </div>
            <div className="info-item">
              <h3>统一信用代码</h3>
              <p className="credit-code">91520382730952396L</p>
            </div>
            <div className="info-item">
              <h3>联系电话</h3>
              <p>400-881-3519</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;