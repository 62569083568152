// src/components/Modal.js
import React, { useEffect } from 'react';
import { useModal } from '../contexts/ModalContext';
import './Modal.css';

const Modal = () => {
  const { showModal, closeModal } = useModal();

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'; // 防止背景滚动
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  if (!showModal) return null;

  const handleOutsideClick = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

  return (
    <div className="modal" onClick={handleOutsideClick}>
      <div className="modal-content" role="dialog" aria-modal="true">
        <p>正在上架，敬请期待...</p>
        <button onClick={closeModal} aria-label="关闭">关闭</button>
      </div>
    </div>
  );
};

export default Modal;