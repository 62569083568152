import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Importing the sections
import WineSlider from './Slider/WineSlider';
import CategorySection from './Category/CategorySection';
import ProductSection from './ProductSection/ProductSection';

function HomePage() {
  return (
    <div className="App">
      {/* Sections on the Home Page */}
      <WineSlider />
      <CategorySection />
      <ProductSection />
    </div>
  );
}

export default HomePage;
