import React, { useState } from 'react';
import './FAQ.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <button className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className="faq-icon">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "国珍酒业的主要产品有哪些？",
      answer: "国珍酒业主要生产高品质的酱香型白酒。我们的产品线包括多个系列，每个系列都有其独特的风味特点和酿造工艺。具体产品信息可以在我们的产品页面查看。"
    },
    {
      question: "国珍酒业的酿酒工艺有什么特点？",
      answer: "我们的酿酒工艺融合了传统和现代。我们严格遵循茅台镇传统的酱香型白酒酿造工艺，同时引入现代化的质量控制措置。我们的酒厂位于茅台镇，利用当地独特的气候和水质优势，确保每一瓶酒都具有卓越的品质。"
    },
    {
      question: "如何购买国珍酒业的产品？",
      answer: "您可以通过多种渠道购买我们的产品。我们有官方网上商城，同时也在各大电商平台设有旗舰店。此外，我们的产品在全国各地的高端酒类专卖店和部分大型超市也有销售。"
    },
    {
      question: "国珍酒业提供参观和品酒体验吗？",
      answer: "是的，我们欢迎酒友们来参观我们的酒厂。我们提供预约参观服务，您可以了解我们的酿酒过程，参观我们的酒窖，并参加品酒活动。请提前至少一周预约，以便我们做好接待准备。"
    },
    {
      question: "国珍酒业的产品适合收藏吗？",
      answer: "非常适合。我们的高端系列产品采用优质原料，经过长时间陈酿，具有很高的收藏价值。我们的酒不仅口感随着时间愈发醇厚，其价值也往往会随时间增长。我们建议在恒温恒湿的环境中存储，以保证酒的品质。"
    }
  ];

  return (
    <div className="faq-page">
      <div className="faq-content">
        <h1>常见问题解答</h1>
        <p className="faq-intro">欢迎来到国珍酒业的常见问题解答页面。这里收集了客户最常咨询的问题，希望能为您提供帮助。如果您没有找到想要的答案，欢迎联系我们的客户服务团队。</p>
        <div className="faq-list">
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;