import React from 'react';
import Slider from 'react-slick';
import { FaArrowRight } from 'react-icons/fa';
import './ProductSection.css';

const products = [
  {
    title: "纪念 1949",
    subtitle: "大国吉祥（纪念1949）",
    image: "/img/products/1.jpg",
    price: "798 元",
    detailsLink: "/product/ispita",
  },
  {
    title: "纪念 1978",
    subtitle: "大国吉祥（纪念1978）",
    image: "/img/products/2.jpg",
    price: "598 元",
    detailsLink: "/product/bad-boy-bordeaux",
  },
  {
    title: "纪念 1997",
    subtitle: "大国吉祥（纪念1997）",
    image: "/img/products/3.jpg",
    price: "398 元",
    detailsLink: "/product/ispita",
  },
  {
    title: "纪念 1949",
    subtitle: "大国吉祥（纪念1949）",
    image: "/img/products/1.jpg",
    price: "798 元",
    detailsLink: "/product/ispita",
  },
];

const favoriteProducts = [
  {
    title: "纪念版 1997",
    subtitle: "国吉祥纪念版1997",
    image: "/img/products/1.jpg",
    price: "398 元",
    detailsLink: "/product/ispita",
  },
  {
    title: "纪念版 1978",
    subtitle: "国吉祥纪念版1978",
    image: "/img/products/2.jpg",
    price: "598 元",
    detailsLink: "/product/bad-boy-bordeaux",
  },
  {
    title: "纪念版 1949",
    subtitle: "大国吉祥（纪念1949）",
    image: "/img/products/3.jpg",
    price: "798 元",
    detailsLink: "/product/bad-boy-bordeaux",
  },
  {
    title: "纪念版 1949",
    subtitle: "大国吉祥（纪念1949）",
    image: "/img/products/3.jpg",
    price: "798 元",
    detailsLink: "/product/ispita",
  },
];

const ProductSection = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.96,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="product-section">
      {/* Best Wines Section */}
      <div className="products-title">
        <h2 className="products-heading">令人自豪的白酒</h2>
        <a href="/shop" className="view-all-link">
        看全部 <FaArrowRight className="arrow-icon" />
        </a>
      </div>
      
      {/* Grid for Desktop, Slider for Mobile */}
      <div className="desktop-only product-grid">
        {products.map((product, index) => (
          <div className="product-box" key={index}>
            <div className="product-image-container">
              <img src={product.image} alt={product.title} className="product-image" />
            </div>
            <div className="product-details">
              <a href={product.detailsLink} className="product-titles">{product.title}</a>
              <p className="product-subtitle">{product.subtitle}</p>
              <p className="product-price">{product.price}</p>
              <a href={product.detailsLink} className="view-details-button">看详情</a>
            </div>
          </div>
        ))}
      </div>
      
      <div className="mobile-only">
        <Slider {...sliderSettings}>
          {products.map((product, index) => (
            <div key={index}>
              <div className="product-box">
                <div className="product-image-container">
                  <img src={product.image} alt={product.title} className="product-image" />
                </div>
                <div className="product-details">
                  <a href={product.detailsLink} className="product-titles">{product.title}</a>
                  <p className="product-price">{product.price}</p>
                  <a href={product.detailsLink} className="view-details-button">看详情</a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Favorite Products Section */}
      <div className="favorite-products-title">
        <h2 className="products-heading">备受欢迎</h2>
        <a href="/shop" className="view-all-link">
        看全部 <FaArrowRight className="arrow-icon"/>
        </a>
      </div>

      {/* Grid for Desktop, Slider for Mobile */}
      <div className="desktop-only favorite-product-grid">
        {favoriteProducts.map((product, index) => (
          <div className="favorite-product-box" key={index}>
            <div className="favorite-product-image-container">
              <img src={product.image} alt={product.title} className="favorite-product-image" />
            </div>
            <div className="favorite-product-details">
              <a href={product.detailsLink} className="favorite-product-title">{product.title}</a>
              <p className="favorite-product-subtitle">{product.subtitle}</p>
              <p className="favorite-product-price">{product.price}</p>
              <a href={product.detailsLink} className="view-details-button">看详情</a>
            </div>
          </div>
        ))}
      </div>

      <div className="mobile-only">
        <Slider {...sliderSettings}>
          {favoriteProducts.map((product, index) => (
            <div key={index}>
              <div className="favorite-product-box">
                <div className="favorite-product-image-container">
                  <img src={product.image} alt={product.title} className="favorite-product-image" />
                </div>
                <div className="favorite-product-details">
                  <a href={product.detailsLink} className="favorite-product-title">{product.title}</a>
                  <p className="product-price">{product.price}</p>
                  <a href={product.detailsLink} className="view-details-button">看详情</a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSection;
