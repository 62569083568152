import React from 'react';
import Slider from 'react-slick';
import { FaArrowRight } from 'react-icons/fa';
import './CategorySection.css'; // Add CSS for styling

const categories = [
  { title: "大国吉祥.纪念系列", image: "/img/categories/1.jpg" },
  { title: "大国吉祥.纪念系列", image: "/img/categories/2.jpg" },
  { title: "大国吉祥.纪念系列", image: "/img/categories/3.jpg" },
  { title: "大国吉祥.纪念系列", image: "/img/categories/1.jpg" },
  { title: "大国吉祥.纪念系列", image: "/img/categories/2.jpg" },
];

const CategorySection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: false,
    slidesToShow: 2.02,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="category-section">
      <div className="section-header">
        <h2 className="section-title">按类别选购</h2>
        <a href="/categories" className="view-all-link">
        看全部 <FaArrowRight className="arrow-icon" />
        </a>
      </div>
      
      {/* Slider for mobile view */}
      <div className="categories-container-mobile">
        <Slider {...settings}>
          {categories.map((category, index) => (
            <div className="category-box" key={index}>
              <div className="category-image-container">
                <img src={category.image} alt={category.title} className="category-image" />
              </div>
              <h3 className="category-title">{category.title}</h3>
            </div>
          ))}
        </Slider>
      </div>

      {/* Grid layout for larger screens */}
      <div className="categories-container">
        {categories.map((category, index) => (
          <div className="category-box" key={index}>
            <div className="category-image-container">
              <img src={category.image} alt={category.title} className="category-image" />
            </div>
            <h3 className="category-title">{category.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySection;
