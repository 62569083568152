import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-content">
        <h1>隐私政策</h1>
        <p className="policy-intro">国珍酒业重视并尊重您的隐私。本隐私政策说明了我们如何收集、使用、披露和保护您的个人信息。请仔细阅读以下内容，了解我们的隐私实践。</p>
        
        <section>
          <h2>1. 信息收集</h2>
          <p>我们可能收集以下类型的信息：</p>
          <ul>
            <li><strong>个人识别信息：</strong>如姓名、电子邮件地址、电话号码、邮寄地址等。</li>
            <li><strong>账户信息：</strong>如用户名、密码等。</li>
            <li><strong>交易信息：</strong>如购买历史、支付详情等。</li>
            <li><strong>设备信息：</strong>如IP地址、浏览器类型、操作系统等。</li>
            <li><strong>使用数据：</strong>如浏览历史、点击记录等。</li>
          </ul>
        </section>

        <section>
          <h2>2. 信息使用</h2>
          <p>我们可能将收集到的信息用于以下目的：</p>
          <ul>
            <li>处理订单和提供客户服务</li>
            <li>改进我们的产品和服务</li>
            <li>个性化用户体验</li>
            <li>发送促销信息和通讯（如果您选择接收）</li>
            <li>防止欺诈和增强安全性</li>
            <li>遵守法律义务</li>
          </ul>
        </section>

        <section>
          <h2>3. 信息共享</h2>
          <p>我们不会出售、出租或交易您的个人信息。但在以下情况下，我们可能会共享您的信息：</p>
          <ul>
            <li>经您同意的第三方服务提供商（如物流公司、支付处理商）</li>
            <li>为遵守法律要求、保护我们的权利或安全</li>
            <li>在公司重组、合并或出售时</li>
          </ul>
        </section>

        <section>
          <h2>4. 数据安全</h2>
          <p>我们采取各种安全措施保护您的个人信息，包括但不限于：</p>
          <ul>
            <li>使用加密技术保护数据传输</li>
            <li>限制员工访问个人信息</li>
            <li>定期进行安全审计</li>
          </ul>
          <p>然而，请注意互联网传输不是100%安全的。我们努力保护您的信息，但不能保证绝对的安全性。</p>
        </section>

        <section>
          <h2>5. Cookie使用</h2>
          <p>我们使用Cookie和类似技术来增强您的浏览体验、分析网站流量和个性化内容。您可以通过浏览器设置控制Cookie的使用。</p>
        </section>

        <section>
          <h2>6. 用户权利</h2>
          <p>根据适用的数据保护法律，您可能拥有以下权利：</p>
          <ul>
            <li>访问您的个人信息</li>
            <li>更正不准确的信息</li>
            <li>删除您的个人信息</li>
            <li>限制或反对处理您的信息</li>
            <li>数据可携带性</li>
          </ul>
          <p>如需行使这些权利，请通过下面提供的联系方式与我们联系。</p>
        </section>

        <section>
          <h2>7. 儿童隐私</h2>
          <p>我们的服务不面向13岁以下的儿童。我们不会故意收集儿童的个人信息。如果您发现我们无意中收集了儿童的信息，请立即联系我们，我们将采取措施删除这些信息。</p>
        </section>

        <section>
          <h2>8. 第三方链接</h2>
          <p>我们的网站可能包含指向第三方网站的链接。我们不对这些网站的隐私政策或内容负责。我们建议您查看这些网站的隐私政策。</p>
        </section>

        <section>
          <h2>9. 政策更新</h2>
          <p>我们可能会不时更新本隐私政策。更新后的政策将在本页面上发布，并注明最后更新日期。我们鼓励您定期查看本政策以了解任何变更。</p>
        </section>

        <section>
          <h2>10. 联系我们</h2>
          <p>如果您对本隐私政策有任何问题、意见或请求，请联系我们：</p>
          <p>隐私官邮箱：privacy@jiuhaozhan.com</p>
          <p>客服电话：400-881-3519</p>
          <p>邮寄地址：贵州省遵义市仁怀市茅台镇岩滩村</p>
        </section>

        <p className="policy-footer">最后更新日期：2024年10月15日</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;