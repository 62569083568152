import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../../data/productsData'; // Ensure you have the correct path
import './ProductDetail.css'; // Assuming you have your CSS here
import RelatedProductsSlider from './RelatedProductsSlider';
import { useModal } from '../../contexts/ModalContext';

const ProductDetail = () => {
  const { openModal } = useModal();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
  };
  const { productId } = useParams();
  const product = products.find((p) => p.detailsLink === `/product/${productId}`);

  const [selectedImage, setSelectedImage] = useState(product?.images[0]);
  const [quantity, setQuantity] = useState(1);
  const [activeTab, setActiveTab] = useState('description');

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const renderLongDescription = (description) => {
    const parts = description.split(/(<img.*?>)/);
    return parts.map((part, index) => {
      if (part.startsWith('<img')) {
        const src = part.match(/src=['"](.*?)['"]/)[1];
        return <img key={index} src={src} alt="Product description" className="description-image" />;
      }
      return <p key={index}>{part}</p>;
    });
  };

  const relatedProducts = [
    { id: 1, title: '纪念 1949', image: '/img/products/1.jpg', price: '¥ 798' },
    { id: 2, title: '纪念 1978', image: '/img/products/2.jpg', price: '¥ 598' },
    { id: 3, title: '纪念 1997', image: '/img/products/3.jpg', price: '¥ 398' },
    { id: 4, title: '纪念 1949', image: '/img/products/1.jpg', price: '¥ 798' },
    { id: 5, title: '纪念 1978', image: '/img/products/2.jpg', price: '¥ 598' },
  ];

  return (
    <div className="product-detail-container">
      <div className="product-detail-wrapper">
        {/* Product Image Section */}
        <div className="product-images">
          <div className="main-image">
            <img src={selectedImage} alt={product.title} />
          </div>
          <div className="thumbnails">
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className={selectedImage === image ? 'active' : ''}
                onClick={() => setSelectedImage(image)}
              />
            ))}
          </div>
        </div>

        {/* Product Information Section */}
        <div className="product-info">
          <h1 className="product-title">{product.title}</h1>
          <p className="product-price">{product.price}</p>
          <p className="product-description">{product.description}</p>

          {/* Quantity Selector */}
          <div className="product-quantity">
            <label htmlFor="quantity">数量:</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              min="1"
              onChange={handleQuantityChange}
            />
          </div>

          {/* Add to Cart Button */}
          <button className="add-to-cart-button"  onClick={handleCategoryClick}>加入购物车</button>
          {/* Product Tabs */}
          <div className="product-tabs">
            <button
              className={activeTab === 'description' ? 'active' : ''}
              onClick={() => setActiveTab('description')}
            >
              描述
            </button>
            <button
              className={activeTab === 'details' ? 'active' : ''}
              onClick={() => setActiveTab('details')}
            >
              细节
            </button>
            <button
              className={activeTab === 'reviews' ? 'active' : ''}
              onClick={() => setActiveTab('reviews')}
            >
              评价
            </button>
          </div>

          {/* Tab Content */}
          <div className="tab-content">
            {activeTab === 'description' && renderLongDescription(product.longDescription)}
            {activeTab === 'details' && (
              <div>
                <p>类型: {product.type}</p>
                <p>度数: {product.alcoholContent}</p>
                <p>产地: {product.origin}</p>
              </div>
            )}
            {activeTab === 'reviews' && <div>评论部分</div>}
          </div>
        </div>
      </div>

     {/* Related Products Slider */}
     <RelatedProductsSlider relatedProducts={relatedProducts} />
    </div>
  );
};

export default ProductDetail;