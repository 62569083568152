// src/components/ProductDetail/RelatedProductsSlider.js
import React from 'react';
import Slider from 'react-slick';
import './RelatedProductsSlider.css'; // Create a CSS file for styles if needed
import { useModal } from '../../contexts/ModalContext';

const RelatedProductsSlider = ({ relatedProducts }) => {
  const { openModal } = useModal();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    openModal();
  };
  const settings = {
    dots: false, // Shows dots for navigation
    infinite: true, // Allows infinite scrolling
    speed: 500, // Animation speed
    slidesToShow: 4, // Number of products to show at once
    slidesToScroll: 1, // Number of products to scroll at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 on medium screens
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 on smaller screens
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2, // Show 1 on extra small screens
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="related-products-slider">
      <h2>相关产品</h2>
      <Slider {...settings}>
        {relatedProducts.map((product) => (
          <div key={product.id} className="related-product">
            <img src={product.image} alt={product.title} />
            <h3>{product.title}</h3>
            <p>{product.price}</p>
            <button className='add-to-cart-btn'  onClick={handleCategoryClick}>加入购物车</button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RelatedProductsSlider;
